import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { ILetterPanel } from '@app/shared/components/letter-panel/types/letter-panel-interface';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { IMakeAChangeDataset } from '../../types/make-a-change-dataset-interface';
import { LetterPanelComponent } from '@app/shared/components/letter-panel/letter-panel.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DELAY } from '@app/shared/constants/delay-enums';
import { PUZZLE_STATUS, ATTEMPT_CONTEXT } from '@app/shared/constants/session-enums';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';

@Component({
  selector: 'app-mac-puzzle-type-1',
  standalone: true,
  imports: [LetterPanelComponent, CommonModule, NgOptimizedImage],
  templateUrl: './puzzle-type-1.component.html',
  styleUrl: './puzzle-type-1.component.scss',
})
export class MACPuzzleType1Component extends PuzzleTypeBaseComponent {
  @Input() currentMacDataset: IMakeAChangeDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Input() newGroup: boolean;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();
  @Output() subPartComplete = new EventEmitter();


  protected letterPanelsActiveWord: ILetterPanel[] = [];
  protected showChain: boolean = false;
  protected formatChain: any[] = [];
  protected resultLetters: any[] = [];
  protected currentLevel = this.appStateService.level;
  protected rightButtonImage = `/assets/arrow-right-dark-${this.currentLevel}.svg`;

  protected prompt: string[] = [];
  protected choices: string[] = [];
  protected answers: string[] = [];
  protected puzzleTypes: number[] = [];

  private targetPanel: ILetterPanel;

  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    this.initActiveWord();

    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PUZZLE_CLUE,
      puzzleClueExpression: '',
    })

    this.resultLetters.push(...this.letterPanelsActiveWord);
    this.resultLetters.forEach((lp) => {
      lp.success = false;
      lp.active = false;
    });

    this.formatChain = this.splitChainResult(this.resultLetters);

    if (!this.currentMacDataset?.expressions) {
      this.showChain = true;
      return;
    } else {
      this.showChain = false;
    }

    if (this.newGroup) {
      this.startPuzzleMetrics();
      this.answers = [];
      this.prompt = [];
    }

    this.resetTries();
    this.setExpressions(this.expressions);
    this.setMaxTries(this.currentMacDataset?.maxTries)

    this.targetPanel = this.letterPanelsActiveWord.filter((lp) => lp.isCorrectAnswer)[0];
    this.validatingPuzzle = false;

    this.soundService.playExpression(this.getExpression(1), () => {
      AnimationHelpers.animate('active-word', ANIMATIONS.Pulse);
    });

    if (!this.showChain) {
      this.appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.PUZZLE_CLUE,
        puzzleClueExpression: this.getExpression(1) ?? '',
      });
    }

 this.letterPanelsActiveWord.forEach(el => {
      if(el.isCorrectAnswer){
        this.answers.push(el.content)
      }
      return;
    });
    this.prompt.push(this.getExpression(1) ?? '')
    this.choices = this.letterPanelsActiveWord.map(el => el.content); // do we even want to display choices?
    this.puzzleTypes = this.currentMacDataset ? [this.currentMacDataset.puzzleType] : [];
    this.addPuzzleMeta(this.prompt,this.choices, this.answers, this.puzzleTypes);
  }

  initActiveWord() {
    if (this.currentMacDataset) {
      AppHelpers.buildTargetWordPanels(this.currentMacDataset.word, this.letterPanelsActiveWord);
      this.letterPanelsActiveWord.forEach((lp) => {
        lp.hidden = false;
      });
    }
  }

  onLetterChoiceSelected(selectedPanel: ILetterPanel) {
    if (this.validatingPuzzle) {
      return;
    }

    this.soundService.playSound(SOUND_NAMES.Click);

    this.validatingPuzzle = true;
    selectedPanel.active = true;

    if (selectedPanel.isCorrectAnswer) {

      this.addUserAttemptDetails({
        content: selectedPanel.content,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.PASS,
        subPartIdx: this.prompt.length - 1
      });

      selectedPanel.success = true;

      // display mark
      AnimationHelpers.animate(selectedPanel?.id, ANIMATIONS.Bounce);
      this.soundService.playSound(SOUND_NAMES.Correct, () => {
        const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
        this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: burstBehind?.id }, BURST_TYPE.Round);
        // clear mark
        this.soundService.playExpression(this.getExpression(3), () => {
          if (this.currentMacDataset) this.currentMacDataset.completed = true;
          this.subPartComplete.emit(false);
          this.emitCall.emit();
        });
      });
    } else {
      selectedPanel.error = true;

      this.addUserAttemptDetails({
        content: selectedPanel.content,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.FAIL,
        subPartIdx: this.prompt.length - 1
      });



      AnimationHelpers.animate(selectedPanel.id, ANIMATIONS.ShakeX);

      if (this.hasTriesExceeded()) {
        this.soundService.playSound(SOUND_NAMES.Incorrect);
        this.addMistake();

        if (!this._selfCorrected) {
          this.subpartSelfCorrected(true);
        }

        const correctPanel = this.letterPanelsActiveWord.filter((lp) => lp.isCorrectAnswer)[0];

        this.soundService.playExpression(this.getExpression(5), () => {
          selectedPanel.error = false;
          selectedPanel.active = false;
          this.soundService.playSound(SOUND_NAMES.Correct);
          AnimationHelpers.animate(correctPanel?.id, ANIMATIONS.Tada);
          correctPanel.success = true;

          if (correctPanel) {
            setTimeout(() => {
              correctPanel.success = false;
              this.subPartComplete.emit(false);
              this.emitCall.emit();
            }, DELAY.S2);
          }
        });
      } else {
        this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
          this.soundService.playExpression(this.getExpression(4), () => {
            selectedPanel.error = false;
            selectedPanel.active = false;
            this.validatingPuzzle = false;
          });
        });
      }
    }
  }

  isLastBeforeWord(index: number, array?) {
    if (array) {
      if (index === array.length - 1) return false;
      return array[index + 1].index === 0;
    } else {
      if (index === this.resultLetters.length - 1) return false;
      return this.resultLetters[index + 1].index === 0;
    }
  }

  private splitChainResult(array) {
    const segmentMax = 6;
    const result: any[] = [];

    let segment: any[] = [];
    let currentWord: any[] = [];

    array.forEach((lp, i) => {
      currentWord.push(lp);
      if (this.isLastBeforeWord(i) && currentWord.length > 0) {
        segment.push(...currentWord);
        currentWord = [];
        if (segment.length <= segmentMax) {
          result.push(segment);
          segment = [];
        }
      }

      if (currentWord.length <= 6 && lp.index === 0) {
        segment.push(...currentWord);
        currentWord = [];

        if (segment.length === segmentMax) {
          result.push(segment);
          segment = [];
        }
      }
    });

    if (currentWord.length > 0) {
      segment.push(...currentWord);
    }

    if (segment.length > 0) {
      result.push(segment);
    }

    return result;
  }

  resumePuzzle() {
    setTimeout(() => {
      this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
      setTimeout(() => {
        this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
      }, DELAY.S1)
    }, DELAY.S1)
    this.formatChain = [];
    this.resultLetters = [];
  }
}
