// pass fail for puzzles
export enum PUZZLE_STATUS {
  FAIL = 0,
  PASS,
  UNKNOWN,
}

// Context for activity mistakes

export enum ATTEMPT_CONTEXT {
  Word = 0,
}
