import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { DELAY } from '@app/shared/constants/delay-enums';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { ATTEMPT_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { COMMA_SEPARATOR, EXCLAMATION_MARK, PERIOD, QUESTION_MARK } from '@app/shared/constants/activity-constants';
import { IWorldOfWordsDataset } from '../../types/world-of-words-dataset-interface';
import { IWord } from '../../types/wow-interfaces';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';

@Component({
  selector: 'app-wow-puzzle-type-1',
  templateUrl: './puzzle-type-1.component.html',
  styleUrls: ['./puzzle-type-1.component.scss'],
  imports: [CommonModule],
  standalone: true,
  providers: [],
})
export class WOWPuzzleType1Component extends PuzzleTypeBaseComponent {
  @Input() currentWorldOfWordsDataset: IWorldOfWordsDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();

  protected isPuzzleSolved = false;

  // Puzzle Type 1
  protected missingWord = '';
  protected sentenceFragments: string[] = [];
  protected wordDefaultText = '';
  protected wordDiv = 'wordDiv';
  protected sentenceChoices: IWord[] = [];
  protected isStaged = false;
  protected wordPlaceHolder = '_';
  protected puzzleTypes: number[] = [];

  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    this.isStaged = false;
    this.validatingPuzzle = false;
    this.setExpressions(this.expressions);
    this.setMaxTries(this.currentWorldOfWordsDataset?.maxTries)
    this.startPuzzleMetrics();
    this.maskTheSentence();

    this.soundService.playExpression(this.getExpression(1), () => { });
  }

  maskTheSentence() {
    if (this.currentWorldOfWordsDataset) {
      const sentence = this.currentWorldOfWordsDataset?.sentence.replaceAll(COMMA_SEPARATOR, ' ,');

      const frags = sentence.split(' ');

      // trim frags
      frags.forEach((f) => {
        f.trimStart();
      });

      const choices = this.currentWorldOfWordsDataset.choices.replaceAll(' ', '').split(',');

      this.sentenceChoices.length = 0;

      // shuffle content
      choices?.sort(() => {
        return 0.5 - Math.random();
      });



      choices.forEach((s, idx) => {
        const choice: IWord = {
          id: AppHelpers.generateId(),
          index: idx,
          word: s,
          display: s,
          valid: (() => {
            const valid =
              this.currentWorldOfWordsDataset?.sentence.toLocaleLowerCase().includes(` ${s.toLowerCase()} `) ||
              this.currentWorldOfWordsDataset?.sentence.toLocaleLowerCase().includes(` ${s.toLowerCase()}.`) ||
              this.currentWorldOfWordsDataset?.sentence.toLocaleLowerCase().includes(` ${s.toLowerCase()},`) ||
              this.currentWorldOfWordsDataset?.sentence.toLocaleLowerCase().includes(` ${s.toLowerCase()}!`) ||
              this.currentWorldOfWordsDataset?.sentence.toLocaleLowerCase().includes(` ${s.toLowerCase()}?`) ||
              this.currentWorldOfWordsDataset?.sentence.toLocaleLowerCase().includes(`${s.toLowerCase()} `) ||
              false;
            return valid;
          })(),
          active: false,
          solved: false,
          error: false,
          correct: false,
        };
        this.sentenceChoices.push(choice);

        if (choice.valid) {
          this.missingWord = choice.display.trimAll();
        }
      });

      const idx = frags.findIndex(
        (f) =>
          f
            .toLowerCase()
            .replaceAll(PERIOD, '')
            .replaceAll(QUESTION_MARK, '')
            .replaceAll(EXCLAMATION_MARK, '')
            .replaceAll(COMMA_SEPARATOR, '') === this.missingWord.toLowerCase().replaceAll(PERIOD, '').replaceAll(EXCLAMATION_MARK, '')
      );

      this.sentenceFragments = [];
      if (idx >= 0) {
        frags[idx] = `*${frags[idx]}`;
        this.sentenceFragments = frags.join(' ').split(frags[idx]);

        if (this.currentWorldOfWordsDataset?.sentence.includes(QUESTION_MARK) && !this.sentenceFragments[1].includes(QUESTION_MARK)) {
          this.sentenceFragments[1] += QUESTION_MARK;
        } else if (
          this.currentWorldOfWordsDataset?.sentence.includes(EXCLAMATION_MARK) &&
          !this.sentenceFragments[1].includes(EXCLAMATION_MARK)
        ) {
          this.sentenceFragments[1] += EXCLAMATION_MARK;
        } else if (this.currentWorldOfWordsDataset?.sentence.includes(PERIOD) && !this.sentenceFragments[1].includes(PERIOD)) {
          this.sentenceFragments[1] += PERIOD;
        }

        // cleanup, remove space before these chars
        this.sentenceFragments[0] = this.sentenceFragments[0]
          .replaceAll(` ${COMMA_SEPARATOR}`, COMMA_SEPARATOR)
          .replaceAll(` ${PERIOD}`, PERIOD)
          .replaceAll(` ${QUESTION_MARK}`, QUESTION_MARK);

        this.sentenceFragments[1] = this.sentenceFragments[1]
          .replaceAll(` ${COMMA_SEPARATOR}`, COMMA_SEPARATOR)
          .replaceAll(` ${PERIOD}`, PERIOD)
          .replaceAll(` ${QUESTION_MARK}`, QUESTION_MARK);
      }
      AnimationHelpers.animate('sentence-host', ANIMATIONS.BackInDown);
      AnimationHelpers.animate('choices', ANIMATIONS.FadeIn);
      const prompt = [this.currentWorldOfWordsDataset?.sentence]
      this.puzzleTypes = this.currentWorldOfWordsDataset ? [this.currentWorldOfWordsDataset.puzzleType] : [];
      this.addPuzzleMeta(prompt,choices, [this.missingWord], this.puzzleTypes);
    }
  }

  onChoiceClicked(choice: IWord) {
    if (this.validatingPuzzle) {
      return;
    }

    this.validatingPuzzle = true;
    this.sentenceChoices.forEach((c) => (c.active = false));
    choice.active = true;

    this.soundService.playExpression(choice.display, () => {
      this.validateSentence(choice);
    });

  }

  onFocusChange(choice: IWord) {
    if (!this.validatingPuzzle) {
      this.sentenceChoices.forEach((c) => (c.active = false));
      choice.active = true;
    }
  }

  capitalizeWord(word: string) {
    if (this.sentenceFragments.length === 0) {
      return word;
    } else if (this.sentenceFragments[0].length === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word;
  }

  validateSentence(choice: IWord) {
    // is puzzle solved ?
    const solved = choice.valid;

    if (solved) {
      this.addUserAttemptDetails({
        content: choice.display,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.PASS,
        subPartIdx: 0
      });

      choice.solved = true;
      this.soundService.playSound(SOUND_NAMES.Correct);
      this.anim.animateTo(choice.id, this.wordDiv, () => {
        this.wordDefaultText = this.capitalizeWord(choice.display);
        this.isStaged = true;
        AnimationHelpers.animate(this.wordDiv, ANIMATIONS.Bounce);
        this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: this.wordDiv }, BURST_TYPE.Round);

        this.soundService.playExpression(this.currentWorldOfWordsDataset?.sentence, () => {
          setTimeout(() => {
            this.completePuzzle(PUZZLE_STATUS.PASS);
          }, DELAY.S1);
        });
      });
    } else {
      this.addUserAttemptDetails({
        content: choice.display,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.FAIL,
        subPartIdx: 0
      });


      choice.error = true;
      AnimationHelpers.animate(choice.id, ANIMATIONS.ShakeX);

      if (this.hasTriesExceeded()) {
        this.soundService.playSound(SOUND_NAMES.Incorrect);
        this.addMistake();

        this.soundService.playExpression(this.getExpression(4), () => {
          this.sentenceChoices.forEach((c) => (c.active = false));
          const validChoice = this.sentenceChoices.find((c) => c.valid);
          if (validChoice) {
            validChoice.active = true;
            this.wordDefaultText = this.capitalizeWord(validChoice.display);
            AnimationHelpers.animate(validChoice.id, ANIMATIONS.Bounce);
          }

          this.isStaged = true;

          choice.error = false;

          AnimationHelpers.animate(this.wordDiv, ANIMATIONS.Bounce);
          this.soundService.playSound(SOUND_NAMES.Correct, () => {
            this.soundService.playExpression(this.currentWorldOfWordsDataset?.sentence, () => {
              setTimeout(() => {
                this.completePuzzle(PUZZLE_STATUS.FAIL);
                this.validatingPuzzle = false;
              }, DELAY.S3);
            });
          });
        });
      } else {
        this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
          this.soundService.playExpression(this.getExpression(3), () => { });
          this.isStaged = false;
          this.validatingPuzzle = false;
          choice.error = false;
        });
      }
    }
  }
}
