import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SYLLABLE_SEPARATOR } from '@app/shared/constants/activity-constants';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { DELAY } from '@app/shared/constants/delay-enums';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { ATTEMPT_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { IWord } from '../../types/wow-interfaces';
import { IWorldOfWordsDataset } from '../../types/world-of-words-dataset-interface';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas'; 

@Component({
  selector: 'app-wow-puzzle-type-3',
  templateUrl: './puzzle-type-3.component.html',
  styleUrls: ['./puzzle-type-3.component.scss'],
  imports: [CommonModule],
  standalone: true,
  providers: [],
})
export class WOWPuzzleType3Component extends PuzzleTypeBaseComponent {
  @Input() currentWorldOfWordsDataset: IWorldOfWordsDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Input() finalParagraphReview: string;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();

  protected isPuzzleSolved = false;

  // Puzzle Type 3
  protected finalParagraph = '';
  protected showOverlay = false;
  protected wordDiv = 'wordDiv';
  protected sentenceChoices: IWord[] = [];
  protected puzzleTypes: number[] = [];

  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    this.setExpressions(this.expressions);
    this.setMaxTries(this.currentWorldOfWordsDataset?.maxTries)
    this.startPuzzleMetrics();
    this.buildSentenceChoices();
    this.soundService.playExpression(this.getExpression(1), () => { });
    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PUZZLE_CLUE,
      puzzleClueExpression: this.getExpression(1)
    });
  }

  buildSentenceChoices() {
    if (this.currentWorldOfWordsDataset) {
      const sentences = this.currentWorldOfWordsDataset.sentence.split(SYLLABLE_SEPARATOR);
      this.sentenceChoices.length = 0;

      sentences.forEach((s, idx) => {
        const choice: IWord = {
          id: AppHelpers.generateId(),
          index: idx,
          word: s.trim(),
          display: s.trim(),
          valid: true,
          active: false,
          solved: false,
          error: false,
          correct: false,
        };
        this.sentenceChoices.push(choice);
      });

      // shuffle content, since we only have 3, make sure always randomized
      this.shuffleSentences();
      const prompt = [this.getExpression(1) ?? '']
      this.puzzleTypes = this.currentWorldOfWordsDataset ? [this.currentWorldOfWordsDataset.puzzleType] : [];
      this.addPuzzleMeta(prompt, this.sentenceChoices.map(s => s.display), sentences, this.puzzleTypes);
    }
  }

  shuffleSentences() {
    const firstSentenceId = this.sentenceChoices.find((s) => s.valid)?.id;
    const maxTries = 10;
    let count = 0;

    while (count++ < maxTries) {
      this.sentenceChoices?.sort(() => {
        return 0.5 - Math.random();
      });
      const id = this.sentenceChoices.find((s) => s.valid)?.id;
      if (id != firstSentenceId) {
        count = 100; // break if it is shuffled.
      }
    }
  }

  onSentenceChoiceClicked(choice: IWord) {
    if (this.validatingPuzzle) {
      return;
    }
    this.validateSentenceChoice(choice);
  }

  validateSentenceChoice(choice: IWord) {
    // is this the correct sentence in order ?
    // put sentences in order again
    const tempSentenceChoices: IWord[] = [];
    Object.assign(tempSentenceChoices, this.sentenceChoices);

    tempSentenceChoices?.sort((a, b) => {
      const val = a.index > b.index ? 1 : 0;
      return a.index < b.index ? -1 : val;
    });

    const nextCorrectChoice = tempSentenceChoices.find((c) => !c.solved);
    const isCorrectChoice = nextCorrectChoice?.index === choice.index;

    this.validatingPuzzle = true;
    if (isCorrectChoice) {
      this.addUserAttemptDetails({
        content: choice.display,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.PASS,
        subPartIdx: 0
      });

      choice.correct = true;
      this.soundService.playSound(SOUND_NAMES.Correct);
      setTimeout(() => {
        this.anim.animateTo(choice.id, this.wordDiv, () => {
          choice.solved = true;
          this.finalParagraph += ` ${choice.display}`;
          this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: this.wordDiv }, BURST_TYPE.Round);
          // is the puzzle solved
          if (!tempSentenceChoices.find((c) => !c.solved)) {
            this.soundService.playExpression(this.getExpression(2), () => {
              setTimeout(() => {
                this.completePuzzle(this.isMaxTriedReached ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
              }, DELAY.S2);
            });
          }
          this.validatingPuzzle = false;
          choice.active = false;
        });
      }, DELAY.S1);
    } else {
      // a mistake
      choice.error = true;
      AnimationHelpers.animate(choice.id, ANIMATIONS.ShakeX);
      this.soundService.playSound(SOUND_NAMES.Incorrect);

      this.addUserAttemptDetails({
        content: choice.display,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.FAIL,
        subPartIdx: 0
      });

      // we always have them finish, but we have to add a mistake.
      this.hasTriesExceeded();

      this.soundService.playExpression(this.getExpression(3), () => {
        choice.active = false;
        choice.error = false;

        // solve 1 of them, they always have to solve the rest..
        const nextValidChoiceIndex = tempSentenceChoices.findIndex((c) => !c.solved);
        const exp = nextValidChoiceIndex != -1 ? this.getExpression(4) : '';
        this.soundService.playExpression(exp, () => { });
        // solve next for them
        this.animateChoice(this.sentenceChoices, nextValidChoiceIndex, () => {
          if (!tempSentenceChoices.find((c) => !c.solved)) { // puzzle complete ?
            this.soundService.playExpression(this.getExpression(2), () => { });
            setTimeout(() => {
              this.completePuzzle(this.hasTriesExceeded() ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
            }, DELAY.S4);
          }
          this.validatingPuzzle = false;
        });
      });
    }
  }

  animateChoice(choices: IWord[] | undefined, choiceIndex: number, callback?: () => void | undefined) {
    const choice = choices?.find((c) => c.index === choiceIndex && !c.solved);

    if (!choice) {
      if (callback) callback();
      return;
    }

    this.anim.animateTo(choice.id, this.wordDiv, () => {
      choice.solved = true;
      this.finalParagraph += ` ${choice?.display}`;
      setTimeout(() => {
        if (callback) callback();
      }, DELAY.S1);
    });
  }

  onPlaySentence(choice: IWord) {
    this.soundService.playExpression(choice.display, () => { });
  }

  onReviewParagraph() {
    // show the last paragraph (puzzle 2)
    this.showOverlay = !this.showOverlay;

    setTimeout(() => {
      AnimationHelpers.animate('light-bulb', ANIMATIONS.Pulse);
    }, DELAY.S1);
  }
}
