<div id="tw-sentence" class="tw-sentence">
  <div id="sentence-host" class="sentence-host">
    <div id="sentence" class="sentence">
      <span>{{ sentenceFragments[0] }}</span>
      <span
        [id]="wordDiv"
        [attr.data-automationId]="'target-word-automation'"
        class="word"
        [ngClass]="{ solved: isPuzzleSolved, staged: isStaged }"
        [attr.aria-hidden]="!isPuzzleSolved"
      >
        {{ isPuzzleSolved || isStaged ? wordDefaultText : wordPlaceHolder }}
      </span>
      <span>{{ sentenceFragments[1] }}</span>
    </div>
  </div>

  <div id="tw-choices" class="choices flex justify-content-center flex-wrap" *ngIf="!isPuzzleSolved">
    <button
      [id]="idx"
      class="choice flex align-items-center justify-content-center border-round m-2 animateSpan"
      *ngFor="let choice of choices; let idx = index"
      (click)="onChoiceClicked(idx)"
      (keyup.enter)="onChoiceClicked(idx)"
      (keyup.space)="onChoiceClicked(idx)"
      (keyup)="parent?.onArrowKey($event)"
      [ngClass]="{ correct: idx === currentCorrectIdx, error: idx === currentErrorIdx, active: idx === currentChoiceIdx }"
      (focusin)="onFocusChange(idx)"
      [attr.data-validAnswer]="choice === trickWordDataset?.word"
    >
      {{ choice }}
      <div class="answer-status" [ngClass]="{ statuscorrect: idx === currentCorrectIdx, statuserror: idx === currentErrorIdx }"></div>
</button>
  </div>
</div>
