<ng-container *ngIf="this.puzzleClueExpression.length > 0 && showRepeat">
  <div class="game-actions-container">
    <button
      *ngIf="puzzleClueExpression.length > 0"
      #audioButton
      class="image-button round help-item"
      (click)="onAudioButtonClick()"
      [disabled]="!showRepeatButton"
      aria-label="Repeat Instructions"
      pTooltip="Repeat Instructions"
      appTrackClick="FHPStuReplay"
    >
      <img [ngSrc]="questionButtonImage" width="58" height="58" alt="repeat button" aria-hidden="true" />
    </button>

    <button
      *ngIf="showHowToPlay"
      #howtovideoButton
      class="image-button round help-item"
      (click)="onHowToVideoButtonClick()"
      (keydown.enter)="onHowToVideoButtonClick()"
      aria-label="How to Play"
      pTooltip="How to Play"
    >
      <img [ngSrc]="howToPlayButtonImage" width="58" height="58" alt="how-to button" aria-hidden="true" />
    </button>
  </div>
</ng-container>
