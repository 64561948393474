<div class="generic-page">
  <div class="generic-page__header container-fluid">
    <div class="container">
      <img src="/assets/fundations-text-white.svg" pTooltip="Fundations" width="214" height="23" alt="Fundations" />
      <div *ngIf="currentUser" class="user-actions">
        <div class="avatar">{{ currentUser.initials }}</div>
        <button class="logout" (click)="logout()" pTooltip="Log out" tooltipPosition="bottom" aria-label="Logout"><i class="pi pi-sign-out"></i></button>
      </div>
    </div>
  </div>
  <div class="page-content container-fluid">
    <router-outlet></router-outlet>
  </div>
</div>
